import { render, staticRenderFns } from "./Buttons.vue?vue&type=template&id=0fc80316&"
import script from "./Buttons.vue?vue&type=script&lang=js&"
export * from "./Buttons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/codebuild/output/src1764022851/src/med-el-hearpeers/components/Icon/index.vue').default})
